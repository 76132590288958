import logo from './logo.svg';
import './App.css';
import {Col, ConfigProvider, Divider, Layout, Result, Row, Space, Table, Tabs, theme} from "antd";
import {
  MailOutlined, WhatsAppOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSackDollar, faCreditCard} from '@fortawesome/free-solid-svg-icons';
import videoUrl from './assets/video/videoplayback2.mp4';
import {Link, useNavigate, useParams, useRoutes} from "react-router-dom";

const {Column, ColumnGroup} = Table;
const {Header, Content} = Layout;

function NoMatch() {
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="Извините, страница, которую вы посетили, не существует.. "
        extra={
          <a href={"/"} type="primary">
            На главную
          </a>
        }
      />
    </div>
  );
}

const Home = ({page='contacts'}) => {
  const [tab, setTab] = useState('contacts')
  const items = [
    {
      key: 'contacts',
      label: <Link to={"/contacts"}>Контакты</Link>,
      children: <Row justify="space-around" align="middle">
        <Col>
          <p>Снами можно связаться по указаным контактам.</p>
          <ul style={{marginBottom: 40, paddingLeft: 15}}>
            <li>+7 707 439 3903</li>
            <li>+7 778 471 8581</li>
            <li>info@vikor.kz</li>
          </ul>
        </Col>
        <Col>
          <a href={'https://api.whatsapp.com/send?phone=77074393903'}
             className={'button'}><WhatsAppOutlined/> Написать в Whatsapp</a>
        </Col>
      </Row>,
    },
    {
      key: 'payment',
      label: <Link to={"/payment"}>Оплата</Link>,
      children: <Row justify="space-around" align="middle">
        <Col>
          <p style={{textAlign: "center", fontSize: 36, marginBottom: 0, color: '#c72321'}}><FontAwesomeIcon
            icon={faSackDollar}/></p>
          <p>Наличный расчет</p>
        </Col>
        <Col>
          <p style={{textAlign: "center", fontSize: 36, marginBottom: 0, color: '#c72321'}}><FontAwesomeIcon
            icon={faCreditCard}/></p>
          <p>Безналичный расчет</p>
        </Col>
      </Row>,
    },
    {
      key: 'delivery',
      label: <Link to={"/delivery"}>Доставка</Link>,
      children: <div>
        <p>1. Доставка транспортной компанией (Доставка товара организуется Продавцом, силами транспортной компании до
          склада Покупателя. Расчет стоимости доставки</p>
        <Table dataSource={[
          {
            n: '1',
            s1: 'Кокшетау',
            s2: 'Алматы',
            nds1: 85,
            nds2: 100,
            schedule: '5-8',
          },
          {
            n: '2',
            s1: 'Кокшетау',
            s2: 'Астана',
            nds1: 85,
            nds2: 105,
            schedule: '2-4',
          },
          {
            n: '3',
            s1: 'Кокшетау',
            s2: 'Караганда',
            nds1: 85,
            nds2: 105,
            schedule: '3-5',
          },
          {
            n: '4',
            s1: 'Кокшетау',
            s2: 'Актобе',
            nds1: 110,
            nds2: 135,
            schedule: '6-12',
          },
          {
            n: '5',
            s1: 'Кокшетау',
            s2: 'Атырау',
            nds1: 130,
            nds2: 150,
            schedule: '6-12',
          },
          {
            n: '6',
            s1: 'Кокшетау',
            s2: 'Актау',
            nds1: 140,
            nds2: 160,
            schedule: '6-12',
          },
          {
            n: '7',
            s1: 'Кокшетау',
            s2: 'Уральск',
            nds1: 110,
            nds2: 135,
            schedule: '6-12',
          },
          {
            n: '8',
            s1: 'Кокшетау',
            s2: 'Костанай',
            nds1: 85,
            nds2: 105,
            schedule: '2-4',
          },
          {
            n: '9',
            s1: 'Кокшетау',
            s2: 'Петропавловск',
            nds1: 85,
            nds2: 105,
            schedule: '2-4',
          },
          {
            n: '10',
            s1: 'Кокшетау',
            s2: 'Кызыл-Орда',
            nds1: 110,
            nds2: 135,
            schedule: '6-12',
          },
          {
            n: '11',
            s1: 'Кокшетау',
            s2: 'Шымкент',
            nds1: 100,
            nds2: 125,
            schedule: '6-12',
          },
          {
            n: '12',
            s1: 'Кокшетау',
            s2: 'Тараз',
            nds1: 100,
            nds2: 125,
            schedule: '6-12',
          },
          {
            n: '13',
            s1: 'Кокшетау',
            s2: 'Усть-Каменогорск',
            nds1: 130,
            nds2: 150,
            schedule: '6-12',
          },
          {
            n: '14',
            s1: 'Кокшетау',
            s2: 'Семей',
            nds1: 130,
            nds2: 150,
            schedule: '6-12',
          },
          {
            n: '15',
            s1: 'Кокшетау',
            s2: 'Павлодар',
            nds1: 85,
            nds2: 110,
            schedule: '3-5',
          },
          {
            n: '16',
            s1: 'Кокшетау',
            s2: 'Рудный',
            nds1: 85,
            nds2: 105,
            schedule: '3-5',
          },
          {
            n: '17',
            s1: 'Кокшетау',
            s2: 'Темиртау',
            nds1: 85,
            nds2: 105,
            schedule: '3-5',
          },
          {
            n: '18',
            s1: 'Кокшетау',
            s2: 'Жезказган',
            nds1: '-',
            nds2: 150,
            schedule: '6-12',
          },
          {
            n: '19',
            s1: 'Кокшетау',
            s2: 'Талдыкорган ',
            nds1: 110,
            nds2: 135,
            schedule: '6-12',
          },
          {
            n: '20',
            s1: 'Кокшетау',
            s2: 'Экибастуз ',
            nds1: 85,
            nds2: 110,
            schedule: '5-10',
          },
          {
            n: '21',
            s1: 'Кокшетау',
            s2: 'Аксай',
            nds1: 130,
            nds2: 150,
            schedule: '6-12',
          },
          {
            n: '22',
            s1: 'Кокшетау',
            s2: 'Кульсары',
            nds1: 120,
            nds2: '-',
            schedule: '6-12',
          },
          {
            n: '23',
            s1: 'Кокшетау',
            s2: 'Бейнеу',
            nds1: 128,
            nds2: '-',
            schedule: '6-12',
          },
          {
            n: '24',
            s1: 'Кокшетау',
            s2: 'Индербор',
            nds1: 128,
            nds2: 148,
            schedule: '6-12',
          },

        ]} pagination={false} size={'small'} scroll={{x: 800}}>
          <Column title="№" dataIndex="n" key="n"/>
          <Column title="Пункт отправки, город" dataIndex="s1" key="s1"/>
          <Column title="Пункт назначения, город" dataIndex="s2" key="s2"/>
          <ColumnGroup title={<>СВЫШЕ 25 КГ <br/>Стоимость за 1 кг, в тенге, с учетом НДС</>}>
            <Column title="без доставки «до двери»" dataIndex="nds1" key="nds1"/>
            <Column title="с доставкой «до двери»" dataIndex="nds2" key="nds2"/>
          </ColumnGroup>
          <Column title="Расписание доставки (рабочие дни)" dataIndex="schedule" key="schedule"/>
        </Table>
        <br/>
        <p>* Забор груза по городу Кокшетау – 1000 тенге. Прием груза на складе Исполнителя — бесплатно.<br/>
          * Все цены указаны с учётом НДС.<br/>
          * Топливная надбавка составляет 10 % от основного тарифа, указанного в таблице.</p>
        <p>2. Самовывоз: г. Кокшетау.</p>
      </div>,
    },
  ];

  useEffect(() => {

    const script = document.createElement("script");
    script.src = "https://apps.elfsight.com/p/platform.js";
    script.async = true;
    document.body.appendChild(script);

    setInterval(() => {
      let leftSection = document.getElementsByClassName('eapps-link');
      if (leftSection.length > 0) {
        leftSection = leftSection[0];
        leftSection.parentNode.removeChild(leftSection);
      }
    }, 1000)
  }, [])

  useEffect(() => {
    if (page) setTab(page); else setTab('contacts');
  }, [page])

  return <Layout>
    <Header className={'my-layout-header'}>
      <div className={'content'}>
        <Row>
          <Col flex={'auto'}>
            <a href="https://spectruck.kz">spectruck.kz</a>
          </Col>
          <Col flex={'auto'} style={{textAlign: 'right'}}>
            <Space size={10}>

                  <span style={{minWidth: 40, display: "inline-block", textAlign: "center"}}>
                    <a href="mailto::info@vikor.kz"><MailOutlined/> <span className={'header-email'}>info@vikor.kz</span></a>
                  </span>

              <span><a href="https://api.whatsapp.com/send?phone=77074393903"><WhatsAppOutlined/> +7 707 439 3903</a></span>
            </Space>
          </Col>
        </Row>
      </div>
    </Header>
    <Content>
      <div className={'content'}>
        <div className={'logo-block'}>
          <div className={'logo-line'}/>
          <div className={'logo-content'}>
            <Link to={'/'}><img src={logo} alt={''}/></Link>
            <p>Монтажные корзины от производителя</p>
          </div>
        </div>

        <p style={{color: '#c1c1c1', textAlign: "center", margin: '25px 0'}}>Купить люльку (монтажную корзину) для
          КМУ по самой выгодной цене легко, достаточно написать или позвонить нам, и мы доставим заказ в любой город
          Казахстана. Мы предлагаем полный комплект крепежа люльки уже в комплекте, для вашего удобства. Наши
          специалисты помогут и проконсультируют вас по всем возникающим вопросам.</p>

        <video style={{width: '100%'}} controls>
          <source src={videoUrl} type="video/mp4"/>
        </video>

        <Tabs activeKey={tab}
              onChange={(activeKey) => setTab(activeKey)}
              items={items}
              centered
              tabBarStyle={{
                color: '#fff'
              }}
        />

        <Divider/>

        <h2 style={{textAlign: 'center', color: '#e5e5e5', marginBottom: 15}}>Наша продукция</h2>

        <div className="elfsight-app-d938e2ee-bbaf-4306-9d7f-a857d1496d07"/>

        <Divider/>

        <p style={{
          textAlign: 'center',
          color: '#999',
          fontSize: 14,
          marginBottom: 25
        }}>Готовы к работе на высоте? Приобретите наши монтажные корзины в полном комплекте прямо сейчас!
          Надежная и удобная люлька, оснащенная всем необходимым для монтажа оборудования.
          Мы предлагаем высокое качество и безопасность на каждом этапе работы.
          Закажите у нас, и получите полный комплект крепежных элементов, чтобы сразу начать эффективный монтаж.
          Ваш успех на высоте начинается с наших монтажных корзин!</p>

        <Divider/>

        <p style={{
          textAlign: 'center',
          color: '#999',
          fontSize: 12,
          marginBottom: 25
        }}>© 2017-{moment().format('YYYY')} Vikor - Монтажные корзины от производителя</p>

      </div>
    </Content>
  </Layout>
}

function App() {

  let element = useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/contacts",
      element: <Home page={'contacts'}/>,
    },
    {
      path: "/payment",
      element: <Home page={'payment'}/>,
    },
    {
      path: "/delivery",
      element: <Home page={'delivery'}/>,
    },
    { path: "*", element: <NoMatch /> },
  ]);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#161616',
        },
        components: {
          header: {
            background: '#fff'
          }
        }
      }}
    >
      {element}
    </ConfigProvider>
  );
}

export default App;
